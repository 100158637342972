<template>
  <b-card>
    <validation-observer ref="formAttendance" v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(save)">
        <b-row>
          <b-col cols="12" class="mb-2"><h3>Ubah Absensi</h3></b-col>

          <b-col cols="12">
            <b-form-group label="NIM" label-for="h-nim" label-cols-md="2">
              <b-form-input
                id="h-nim"
                placeholder="Nomor Induk Mahasiswa"
                disabled
                v-model="model.nim"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Nama Mahasiswa"
              label-for="h-name"
              label-cols-md="2"
            >
              <b-form-input
                id="h-name"
                placeholder="Nama Mahasiswa"
                disabled
                v-model="model.name"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Persentase Kehadiran"
              label-for="h-attendance"
              label-cols-md="2"
            >
              <validation-provider
                #default="{ errors }"
                name="Persentase Kehadiran"
                rules="required"
              >
                <b-form-input
                  id="h-attendance"
                  placeholder="Persentase Kehadiran"
                  :disabled="config.action === 'show'"
                  v-model="model.attendance"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col class="text-right">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mr-1"
              @click="$router.go(-1)"
            >
              Kembali
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
            >
              Simpan
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardText,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      config: {
        action: this.$route.meta.permission.action,
        api: "attendances",
      },
      model: {},
    };
  },
  created() {
    const _ = this;
    _.loadData();
  },
  methods: {
    loadData() {
      const _ = this;
      _.model = _.$store.getters["attendance/GET_ATTENDANCE"];
    },
    save() {
      const _ = this;

      _.$axios
        .put(`${_.config.api}/${_.$route.params.id}`, {
          attendance: _.model.attendance,
        })
        .then((res) => {
          _.$router.push({
            name: _.config.redirect,
            params: {
              variant: "success",
              text: "Update Kehadiran Berhasil",
            },
          });
        });
    },
  },
};
</script>

<style></style>
